<template>
  <div>
    <script @load="authLoaded" src="https://secure.aadcdn.microsoftonline-p.com/lib/1.0.15/js/adal.min.js" integrity="sha384-lIk8T3uMxKqXQVVfFbiw0K/Nq+kt1P3NtGt/pNexiDby2rKU6xnDY8p16gIwKqgI" crossorigin="anonymous" type="application/javascript"></script>
    <v-container class="" fluid> 
      <v-row >
        <v-col cols="12">
          <v-dialog v-if="selectedEvent && selectedRace" v-model="showLeaderboardDialog" :elevation="2">
            <v-btn id="closeDialog" text large color="white" @click="clearSubState"><v-icon class="mr-2">fa-times-circle</v-icon> {{$t('shared.close')}}</v-btn>
            <v-card :dark="isDark">
              <v-card-title class="headline">
                <span class="mr-2">{{$t('events.race.leaderboard')}}</span>
                <span class="mr-2">{{ selectedRace.name }}</span>
                <span v-if="meta && meta.total_count" class="" style="font-size:70%;">({{meta.total_count}} {{$t('events.race.results-label')}})</span>
              </v-card-title>
              <RaceResultFilter :meta="meta" :event="selectedEvent" :race="selectedRace" :title="$t('events.race.title')" :filter="resultFilter" :showRace="false" @change="updateResults" ref="filter"/>
              <RaceResultsMap ref="resultMap" :event="selectedEvent" :race="selectedRace" :badges="badges" :meta="meta"/>
              <vue-markdown v-if="selectedRace.result_desc" class="markdown mx-4" :html="false" :source="selectedRace.result_desc" />
              <RaceResultsGrid :event="selectedEvent" :race="selectedRace" :results="results" :meta="meta" :badges="badges" :filter="resultFilter" @search="updateSearch"/>
              <v-pagination 
                v-if="meta && meta.total_count > 50 && !hasFilter && !subResults" 
                v-model="resultPage" 
                circle 
                :length="Math.ceil(meta.total_count / 50)" 
                @input="loadPage"
                />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="clearSubState">{{$t('shared.close')}}</v-btn>
              </v-card-actions>      
            </v-card>
          </v-dialog>
          <v-card :elevation="1" :dark="isDark">
            <v-card-title v-if="$store.getters.loading && !event" class="headline">Loading Challenge...</v-card-title>

            <v-skeleton-loader v-if="$store.getters.loading && !event" type="table"></v-skeleton-loader>
            <div v-if="event">
              <v-card-title class="headline">{{event.name}}</v-card-title>
              <v-card-text v-if="!event.published" class="text-italic">
                This event is not yet published. You can join once it's published by the organizer.
              </v-card-text>
              <vue-markdown v-if="event.intro" class="markdown mx-4" :html="false">{{event.intro }}</vue-markdown>

              <div v-if="$helpers.isFutureEvent(event)" class="mx-4">
                <CountDownTimer :label="$t('events.rules.starts-in')" :countDownDate="event.from" />
              </div>
              <div v-if="event.pinned_race_id">
                <RaceCustomView :event="event" :race="event.races.find(x => x.id == event.pinned_race_id)" />
                <v-divider/>
                <!-- Show the leaderboards header only when there is something pinned at the top -->
                <v-card-title class="subtitle">{{$t('events.races-and-leaderboards')}}</v-card-title>
              </div>
              <v-row class="mx-2">
                <v-col cols="12" sm="4" md="3" v-for="(race, raceIdx) in event.races" :key="raceIdx">
                  <RaceCard 
                    :item="race" 
                    :event="event" 
                    :index="raceIdx" 
                    :color="$helpers.getRaceColor(event, raceIdx)" 
                    @click="selectLeaderboard(event.id, race.id)"
                    />
                </v-col>
              </v-row>
              <div v-if="$helpers.isActiveEvent(event)" class="mx-4">
                <CountDownTimer :label="$t('events.rules.finishes-in')" :countDownDate="event.till" />
              </div>
              <v-card-text v-if="!event.published && canManage" class="">
                <v-alert v-if="!event.races || event.races.length == 0" type="warning">
                  Please use the Manage option to add at least one leaderboard to your event.
                </v-alert>
                <v-alert v-else type="info">
                  <p>You can publish this event for free for up to 5 users. Please upgrade to a PRO event when you expect more users.</p>
                  <p>You can invite your team members to the challenge from the <strong>Add Users</strong> screen.</p>
                  <p v-if="false">We will<strong> automatically invite all users</strong> connected with this {{objectType}} when you publish this event.</p>
                  <p>
                    <v-btn @click="publishEvent(event)" :loading="$store.getters.isLoading">Publish</v-btn>
                    <v-btn v-if="teamsWrapper.showPurchaseOptions()" class="ml-4" target="_blank" :to="teamsWrapper.hidePurchaseOptions() ? {name: 'pricing', query: { context:'microsoft-teams'}} : { name: 'eventmanagerLicense', params: {id: event.id}}">Upgrade to PRO <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
                    <span v-if="teamsWrapper.hidePurchaseOptions()">Open the backend to upgrade your license.</span>
                  </p>
                </v-alert>
              </v-card-text>

              <v-card-actions v-if="event" class="mx-2">
                <v-btn v-if="event.published" outlined :color="event.color || 'primary'" class="mr-4" @click="recoverInviteLink" :loading="$store.getters.isLoading"><v-icon small class="mr-2">fa fa-plus</v-icon> {{$t('events.welcome.join-this-event')}}</v-btn>
                <v-btn v-if="false && event.published && teamsContext.userObjectId" outlined :color="event.color || 'primary'" class="mr-4" @click="showJoinOptions(event)" :loading="$store.getters.isLoading"><v-icon small class="mr-2">fa fa-plus</v-icon> {{$t('events.welcome.join-this-event')}}</v-btn>
                <v-dialog v-if="quickentryUrl" v-model="showManualEntryDialog" max-width="700px">
                  <template v-slot:activator="{ on }">
                    <v-btn text :color="event.color || 'primary'" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> Manual Entry</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="mb-8">
                      <span class="grow">{{$t('profile.activities.track.add-an-activity')}}</span>
                      <v-icon class="shrink" @click="showManualEntryDialog=false">fa-times-circle</v-icon>
                    </v-card-title>
                    <div class="mx-4" style="display:flex; flex-direction: column; min-height: 150px; overflow:hidden;">
                      <v-progress-circular v-if="!frameLoaded" color="primary" size="100" class="align-center" indeterminate style="align-self:center;" />
                      <iframe v-show="frameLoaded" width="100%" height="600px" style="border:0;overflow-x:hidden; -webkit-overflow-scrolling: touch;" loading="lazy" :src="quickentryUrl+'&view=app'" @load="frameLoaded=true"></iframe>
                    </div>
                  </v-card>
                </v-dialog>
                <v-btn v-if="event.published" text class="mr-4" target="_blank" :to="{ name: 'eventGettingStarted', params: {id: event.id}}"><v-icon small class="mr-2">fa fa-info-circle</v-icon> Get Started <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>

                <v-dialog v-model="showJoinDialog" max-width="400px">
                  <v-card>
                    <v-card-title>{{$t('events.welcome.join-this-event')}}</v-card-title>
                    <v-divider class="mb-4"></v-divider>
                    <v-card-text v-if="showQr">
                      <p>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</p>
                      <p>This is your <strong>personal</strong> join link for code <strong>{{joinInfo.join_code}}</strong> and can only be used once.</p>
                      <QrCode :data="joinInfo.join_url" />
                    </v-card-text>
                    <v-card-text v-else-if="joinInfo && joinInfo.join_url">
                      <p>On which device would you like to join?</p>
                      <v-card :href="joinInfo.join_url" target="_blank" class="pa-4 mt-4">
                        <h3>On <u>this</u> device <v-icon x-small color="black" class="ml-1">fa-external-link</v-icon></h3>
                        <p>Download the app or join online, depending on the type of your device.</p>
                      </v-card>
                      <v-card @click="showQr=true" class="pa-4 mt-4">
                        <h3>On <u>another</u> device</h3>
                        <p>Show a QR code which you can scan with your phone to download the app and get started.</p>
                      </v-card>
                    </v-card-text>
                    <v-card-text v-else>
                      You should receive a personal invite email shortly. Please also check your spam/junk folder if you don't receive this email in the next hour.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn color="secondary" text @click="showJoinDialog = false">{{$t('shared.cancel')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>              
                
              </v-card-actions>
              <vue-markdown v-if="event.description" class="markdown mx-2" :html="false">{{event.description }}</vue-markdown>
              
              <v-divider v-if="event.published && licenseInfo" :dark="isDark" />
              <v-card-text v-if="event.published && licenseInfo" :class="isDark ? 'grey darken-4' : 'grey lighten-4'">
                <p v-if="licenseInfo.valid">
                  <v-icon color="green">fa-check-circle</v-icon>
                  Valid {{event.license}} license.
                  <v-btn v-if="teamsWrapper.showPurchaseOptions()" text color="primary" target="_blank" :to="teamsWrapper.hidePurchaseOptions() ? {name: 'pricing', query: { context:'microsoft-teams'}} : {name: 'eventmanagerLicense', params: {id: event.id}}">
                    Purchase Now <v-icon x-small class="ml-1">fa-external-link</v-icon>
                  </v-btn>                            
                  <span v-if="teamsWrapper.hidePurchaseOptions()">Open the backend to upgrade your license.</span>
                </p>
                <v-alert v-else type="error" prominent>
                  <p>All credits used. No new users can join this challenge until new credits are purchased.</p>
                  <p>
                    <v-btn v-if="teamsWrapper.showPurchaseOptions()" target="_blank" :to="teamsWrapper.hidePurchaseOptions() ? {name: 'pricing', query: { context:'microsoft-teams'}} : {name: 'eventmanagerLicense', params: {id: event.id}}">Purchase Now <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>                            
                    <span v-if="teamsWrapper.hidePurchaseOptions()">Open the backend to upgrade your license.</span>
                  </p>
                </v-alert>
                <div class="d-flex flex-row">
                  <v-progress-linear striped class="ma--4" height="25" color="green" :value="Math.round(licenseInfo.current_capacity / licenseInfo.max_capacity * 100)" />
                  <div class="shrink mt-0 ml-4" style="white-space:nowrap;">{{ licenseInfo.max_capacity }} users</div>
                </div>
                <span class="text-muted">{{ licenseInfo.current_capacity }} users out of {{ licenseInfo.max_capacity }} users have joined. Actual number of joined users might have a slight processing delay.</span>
              </v-card-text>
            </div>

            <v-divider :dark="isDark"/>
            
            <v-alert v-if="!event && !$store.getters.loading" type="info" prominent outlined class="mx-3" :dark="isDark">
              <h3>This tab is disconnected from it's event</h3>
              <p>Please reinstall the tab to  show your event.</p>
            </v-alert>
            
            <!-- iOS policy restrictions prevent direct upgrade path -->
            <v-alert v-if="canManage && teamsWrapper.hidePurchaseOptions()" type="info" outlined class="ma-3" :dark="isDark">
              <h3>Contact Us to Upgrade</h3>
              <p>Please contact us to discuss options to upgrade your license or open your event in the backend to do so directly.</p>
            </v-alert>

            <!-- <v-card-text v-if="user">Manage the event in this chat/channel.</v-card-text> -->
            <v-card-actions v-if="user && event">
              <!--
              <v-btn text :to="{name: 'eventmanagerCreate', query: { teamsInfoJson: this.teamsInfoJson }}">Create Challenge</v-btn>
              <v-btn text :to="{name: 'eventmanagerLinkTeams', query: { teamsInfoJson: this.teamsInfoJson }}">Link Existing</v-btn>
              -->
              <v-btn v-if="user && canManage" text :color="event.color" class="mr-4" target="_blank" :to="{ name: 'eventmanagerView', params: {id: event.id}}"><v-icon small class="mr-2">fa fa-cogs</v-icon> Manage <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
              <v-btn v-if="user && canManage" text :color="event.color" class="mr-4" target="_blank" :to="{ name: 'eventmanagerRegistrations', params: {id: event.id}}"><v-icon small class="mr-2">fa fa-users</v-icon> Add Users <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>

              <v-btn text :color="event.color" class="mr-4" target="_blank" :to="{ name: 'eventGettingStarted', params: {id: event.id}}"><v-icon small class="mr-2">fa fa-question-circle</v-icon> How-to Guides <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
              <v-btn text href="https://corporatefitness.helpscoutdocs.com/category/8-integrations" target="_blank"><v-icon small class="mr-2">fa fa-books</v-icon>FAQ <v-icon x-small color="" class="ml-1">fa-external-link</v-icon></v-btn>
              <v-spacer/>
              <v-btn text @click="signOut">Sign out</v-btn>
            </v-card-actions>
            <v-card-text v-if="!user">Are you the organizer of this challenge? Please log in to manage the event in this chat/channel.</v-card-text>
            <v-card-actions v-if="!user">
              <v-btn v-if="event" text :color="event.color" class="mr-4" target="_blank" :to="{ name: 'eventGettingStarted', params: {id: event.id}}"><v-icon small class="mr-2">fa fa-question-circle</v-icon> How-to Guides <v-icon x-small class="ml-1">fa-external-link</v-icon></v-btn>
              <v-btn text @click="login">Log In</v-btn>
              <v-btn v-if="false && !userMicrosoft" text @click="loginMicrosoft">Log In (Microsoft)</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="false && teamsContext" class="text-muted mt-16" align="center" justify="center" style="font-size: 80%;">
        <v-btn v-if="!showDebug" text color="grey" x-small @click="showDebug=true">Show debug info</v-btn>
        <v-col v-if="showDebug" cols="12" sm="8" md="6">
          <v-divider class="mb-4" />
          <p>Debug information:</p>
          <p v-if="teamsContext.userObjectId">User '{{teamsContext.upn}}' (id: '{{teamsContext.userObjectId}}')</p>
          <p v-if="teamsContext.chatId">Loaded in chat '{{teamsContext.chatId}}' (tenant: '{{teamsContext.tid}}')</p>
          <p v-else-if="teamsContext.channelId">Loaded in channel '{{teamsContext.channelId}}' (team: '{{teamsContext.teamId}}', tenant: '{{teamsContext.tid}}')</p>
          <p v-if="user"><v-btn text @click="signOut">Sign out</v-btn></p>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showRecoveryDialog" max-width="500">
      <v-card>
        <v-card-title>Retrieve your join link</v-card-title>
        <v-card-text v-if="showQr">
          <p>Scan this QR code with your phone to quickly join this event using our app. This will download the iOS or Android depending on the phone.</p>
          <p>This is your <strong>personal</strong> join link for code <strong>{{joinInfo.join_code}}</strong> and can only be used once.</p>
          <QrCode :data="joinInfo.join_url" />
        </v-card-text>
        <v-card-text v-else-if="joinInfo && joinInfo.join_url">
          <v-alert type="success">All good, we found your information!</v-alert>
          <p>On which device would you like to join?</p>
          <v-card :href="joinInfo.join_url" target="_blank" class="pa-4 mt-4">
            <h3>On <u>this</u> device <v-icon x-small color="black" class="ml-1">fa-external-link</v-icon></h3>
            <p>Download the app or join online, depending on the type of your device.</p>
          </v-card>
          <v-card @click="showQr=true" class="pa-4 mt-4">
            <h3>On <u>another</u> device</h3>
            <p>Show a QR code which you can scan with your phone to download the app and get started.</p>
          </v-card>
        </v-card-text>
        <v-card-text v-else-if="showRequestInviteOptions">
          <v-alert v-if="joinInfo" type="warning">Cannot find your information. Please ensure you have no typos in your email or contact the event organizer <a v-if="joinInfo.support_email" :href="`mailto:${joinInfo.support_email}`">at {{joinInfo.support_email}}</a> to get your link or request an invite using form below.</v-alert>
          <v-text-field v-model="email" autofocus label="Email" />
          <v-text-field v-model="given_name" label="First/given name (optional)" />
          <v-text-field v-model="family_name" label="Last/family name (optional)" />
          <v-text-field v-model="message" label="Message (optional)" />
          <v-btn color="primary" :disabled="!email" @click="requestInvite">Request Invite</v-btn>
          <v-btn color="primary" text @click="joinInfo=null">Try Again</v-btn>
        </v-card-text>
        <v-card-text v-else-if="recoveryType=='CODE'">
          <p>Please enter your personal verification code:</p>
          <v-text-field v-model="code" autofocus />
          <v-btn color="primary" :disabled="!code" @click="loadJoinStatusByCode">Find</v-btn>
        </v-card-text>
        <v-card-text v-else-if="recoveryType=='EMAIL'">
          <p>Please enter the email address used by this event:</p>
          <v-text-field v-model="email" autofocus label="Email" />
          <v-btn color="primary" :disabled="!email" @click="loadJoinStatusByEmail">Find</v-btn>
        </v-card-text>
        <v-card-text v-else>
          <p>How do you want to retrieve your link??</p>
          <v-card @click="recoveryType='CODE'" class="pa-4 mt-4">
            <h3>By <u>verification code</u></h3>
            <p>When you know your personal verification code.</p>
          </v-card>
          <v-card @click="recoveryType='EMAIL'" target="_blank" class="pa-4 mt-4">
            <h3>By <u>email</u></h3>
            <p>Enter your email to retrieve your link.</p>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="showRecoveryDialog=false;">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth, onAuthStateChanged, signOut, signInWithCustomToken } from "firebase/auth";
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import EventUtil from "@/util/eventUtil";
import MicrosoftTeamsWrapper from "@/util/microsoftTeamsWrapper";
import RaceCard from "@/components/RaceCard.vue";
import VueMarkdown from '@/components/VueMarkdown.vue'
import QrCode from '@/components/generic/QrCode.vue'
import CountDownTimer from "@/components/generic/CountDownTimer";
import RaceCustomView from '@/components/RaceCustomView.vue';
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import RaceResultFilter from "@/components/RaceResultFilter.vue";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
const tenant = tenants.current();
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
    RaceCard,
    VueMarkdown,
    CountDownTimer,
    RaceResultFilter,
    RaceResultsGrid,
    RaceResultsMap,
    RaceCustomView,
    QrCode,
  },
  props: {
    microsoftTeams: Object,
    teamsContext: Object,
  },
  data: () => ({
    event: null,
    licenseInfo: null,
    showDebug: false,
    userMicrosoft: null,
    showJoinDialog: false,
    showLeaderboardDialog: false,
    showRecoveryDialog: false,
    showQr: false,
    code: null,
    email: null,
    given_name: null,
    family_name: null,
    message: null,
    recoveryType: null,
    joinInfo: null,
    canManage: false,
    frameLoaded: false,
    showManualEntryDialog: false,

    selectedRaceKey: null,

    meta: null,
    markers: null,
    results: null,
    badges: null,
    resultFilter: null,
    resultPage: 1,
  }),

  async mounted() {
    await this.loadUserData();
    EventBus.$on('login-state-change', async user => {
      await this.loadUserData();
    });
    this.$store.commit('setContext', 'microsoft-teams');
/*    const auth = getAuth();
    //console.log('AUTH getAuth', auth, onAuthStateChanged);
    onAuthStateChanged(auth, async user => {
      console.log('AUTH onAuthStateChanged', user);
      await this.loadUserData();
    });*/
  },

  methods: {
    async loadUserData() {
      console.log('AUTH STATE changed', this.user);
      if (this.user) {
        this.loadConnectedEvents();
      }
    },
    async authLoaded() {
      //this.tryLoadAuthState();
    },
    async publishEvent(event) {
      var eventUtil = new EventUtil(this, { id: event.id});
      await eventUtil.refresh();
      eventUtil.event.published = true;
      if (await eventUtil.save()) {
        this.loadConnectedEvents();
      }
    },

    async recoverInviteLink() {
      this.email = this.code = this.showQr = this.joinInfo = this.recoveryType = null;
      this.showRecoveryDialog = true;
    },
    async loadJoinStatusByCode() {
      var response = await eventService.getJoinInfo(this.event.id, this.code);
      this.joinInfo = response.data;
    },
    async loadJoinStatusByEmail() {
      var response = await eventService.getJoinInfoByEmail(this.event.id, this.email); 
      this.joinInfo = response.data;
    },
    async requestInvite() {
      var response = await eventService.requestInvite(this.event.id, {
        email: this.email,
        given_name: this.given_name,
        family_name: this.family_name,
        message: this.message,
      }); 
      this.$helpers.toastResponse(this, response.data, '👍 Your request has been sent to the event organizer.');
      this.joinInfo = null;
      this.showRecoveryDialog = false;
    },

    async tryLoadSubState() {
      console.log('tryLoadSubState, subId:', this.selectedRaceKey, this.selectedEventId, this.selectedRaceId);
      if (this.event && this.selectedEventId && this.selectedRaceId) {
        // load details 
        this.badges = null;
        this.results = null;
        this.markers = null;
        this.showLeaderboardDialog = true;
        this.loadBadges();
        this.loadResults();
        this.loadMap();
      }
      else {
        this.showLeaderboardDialog = false;
      }
    },
    async selectLeaderboard(eventId, raceId) {
      const subId = `${eventId}_${raceId}`;
      /*if (this.microsoftTeams) {
        this.microsoftTeams.settings.setSettings({
          subEntityId: subId,
        });        
      }*/
      // simulator
      this.selectedRaceKey = subId;
      await this.tryLoadSubState();
    },
    async clearSubState() {
      console.log('clearSubState');
      /*if (this.microsoftTeams) {
        this.microsoftTeams.settings.setSettings({
          subEntityId: '',
        });        
      }*/
      // simulator
      this.selectedRaceKey = '';
      console.log('///', this.microsoftTeams, this.selectedRaceKey);
      await this.tryLoadSubState();
    },
    async updateSearch(query) {
      this.$refs.filter.loadFilter({search: query });
      this.$refs.filter.search();
    },

    async loadResults() {
      await this.loadPage(1);
    },
    async updateResults(filter) {
      this.resultPage = 1;
      this.resultFilter = filter;
      await this.loadResults(this.eventId, this.raceId);
    },
    async loadNextPage() {
      await this.loadPage(this.resultPage == null ? 2 : this.resultPage+1);
    },
    async loadPage(page) {
      this.resultPage = page;
      const response = (await eventService.getRaceResults(this.selectedEventId, this.selectedRaceId, this.resultFilter, this.resultPage)).data;
      this.results = response.data;
      this.meta = response.meta;
    },
    async loadBadges() {
      if (this.badges == null) {
        this.badges = (await eventService.getRaceBadges(this.selectedEventId, this.selectedRaceId)).data.data;
        if (this.selectedRace.route) {
          this.$refs.resultMap.ensureBadgesLoaded(this.badges);
        }
      }
    },
    async loadMap() {
      //console.log('Loading map?', this.selectedRace.route);
      if (!this.selectedRace.route) {
        return;
      }
      //await this.selectedRace.initMap();
      if (!this.hasFilter) {
        const markerResponse = (await eventService.getRaceMarkers(this.selectedEventId, this.selectedRaceId)).data;
        this.markers = markerResponse.data || [];
      }

      if (this.markers && this.markers.length > 0) {
        this.$refs.resultMap.loadMarkers(this.markers, this.meta);
      }
      else {
        this.$refs.resultMap.loadResults(this.results, this.meta);
      }
    },

    async loadConnectedEvents() {
      if (!this.teamsContext) {
        return; 
      }
      if (this.user) {
        try {
          // when logged in, try to load full management status (if permissions allow)
          this.event = (await eventManagerService.get(this.teamsContext.entityId)).data;
          this.canManage = true;

          if (this.event) {
            this.licenseInfo = (await eventManagerService.getLicenseStatus(this.teamsContext.entityId)).data;
          }
        }
        catch {}
      }
      if (!this.event) {
        this.event = (await eventService.get(this.teamsContext.entityId, /*allowDraft:*/true)).data;
      }
      /*if (this.teamsContext.chatId) {
        this.events = (await eventService.getForTeamsChat(this.teamsContext.tid, this.teamsContext.chatId)).data.data;
      }
      else {
        this.events = (await eventService.getForTeamsChannel(this.teamsContext.tid, this.teamsContext.teamId, this.teamsContext.groupId, this.teamsContext.channelId)).data.data;      
      }*/
      await this.tryLoadSubState();

      if (this.event) {
        // start loading connected information
        await this.loadJoinStatus(this.event);
      }
    },
    async teamsLoaded() {
      this.microsoftTeams = window.microsoftTeams;
      //await this.tryLoadAuthState();

      //this.microsoftTeams.initialize();
      //this.microsoftTeams.appInitialization.notifyAppLoaded();
      //this.microsoftTeams.appInitialization.notifySuccess();
    },
    async loadJoinStatus(event) {
      var response = await eventService.getJoinInfo(event.id, this.teamsContext.userObjectId);
      this.joinInfo = response.data;
    },
    async showJoinOptions(event) {
      if (!this.joinInfo) {
        await this.loadJoinStatus(event);
      }
      if (this.joinInfo) {
        this.showJoinDialog = true;
      }
    },
    async signOut() {
      await this.teamsWrapper.signOut();
    },
    async login() {
      await this.teamsWrapper.login();
    },

  },
  computed: {
    isDark() {
      return this.teamsWrapper.isDarkTheme();
    },
    teamsWrapper() {
      return new MicrosoftTeamsWrapper(this, this.microsoftTeams, this.teamsContext);
    },
    teamsInfoJson() {
      if (this.teamsContext == null) return null;
      return JSON.stringify(this.teamsWrapper.getTeamsInfo());
    },
    objectType() {
      if (this.teamsContext != null && this.teamsContext.channelId) {
        return 'channel';
      }
      return 'chat';
    },
    showRequestInviteOptions() {
      return this.joinInfo && !this.joinInfo.join_url;
    },
    quickentryUrl(){
      if (this.joinInfo && this.joinInfo.quickentry_url) {
        return this.joinInfo.quickentry_url;
      }
      if (this.selectedEvent.published){
        return `${window.location.origin}/events/${this.selectedEvent.id}/quickentry?view=app`;
      }
      return null;
    },
    selectedEventId() {
      return this.selectedRaceKey && this.selectedRaceKey.includes('_') && this.selectedRaceKey.split('_')[0];
    },
    selectedRaceId() {
      return this.selectedRaceKey && this.selectedRaceKey.includes('_') && this.selectedRaceKey.split('_')[1];
    },

    selectedEvent() {
      return this.event;
      if (!this.events || !this.selectedEventId) {
        return null;
      }
      return this.events.find(x => x.id == this.selectedEventId);
    },
    selectedRace() {
      if (!this.selectedRaceId || !this.selectedEvent) {
        return null;
      }
      return this.selectedEvent.races.find(x => x.id == this.selectedRaceId);
    },

    ...mapGetters({
      user: "user"
    }), 
  },
  watch: {
    microsoftTeams() {
      console.log('teams tab content loaded');
    },
    teamsContext() {     
      this.loadConnectedEvents();
      this.selectedRaceKey = this.teamsContext.subEntityId;
    },
  }
};
</script>
<style lang="scss" scoped>

 .v-card__actions {flex-flow: wrap;}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn { margin-left: 0; }
</style>

